import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
  redirect,
} from "@remix-run/node";

import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useRouteError,
} from "@remix-run/react";
import mobileCSS from "./styles/mobile.css?url";
import { useEffect } from "react";
import { trackPageView } from "./analytics.client";

import AppContextProvider from "~/utils/app-content-provider";
import { SnackbarProvider } from "~/components";
import ComponentSvg from "./components/component-svg";

import { AuthLoadingProvider } from "~/utils/auth-loading-context";

export const links: LinksFunction = () => {
  return [{ rel: "stylesheet", href: mobileCSS }];
};

export const meta: MetaFunction = () => {
  return [{ title: "FlirtyBum" }];
};

export function Layout({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  useEffect(() => {
    trackPageView();
  }, [location.key]);

  return (
    <html lang="en" className="hydrated">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, interactive-widget=resizes-content, viewport-fit=cover"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          httpEquiv="Delegate-CH"
          content="sec-ch-ua https://ads.trafficjunky.net; sec-ch-ua-arch https://ads.trafficjunky.net; sec-ch-ua-full-version-list https://ads.trafficjunky.net; sec-ch-ua-mobile https://ads.trafficjunky.net; sec-ch-ua-model https://ads.trafficjunky.net; sec-ch-ua-platform https://ads.trafficjunky.net; sec-ch-ua-platform-version https://ads.trafficjunky.net;"
        />
        <Meta />
        <Links />
      </head>
      <body className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed toolbar-tablet-and-mobile-fixed">
        <div id="root" className="d-flex flex-column flex-root dark-bg">
          <AuthLoadingProvider>{children}</AuthLoadingProvider>

          <ScrollRestoration />
        </div>
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz"
          crossOrigin="anonymous"
        ></script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-S205G2B9BS"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-S205G2B9BS');
          `,
          }}
        />
        <Scripts />
        <img
          src="https://ads.trafficjunky.net/rt?action=list&type=add&id=click&context=flirtybum&cookiename=flirtybum&maxcookiecount=10"
          style={{ height: 0, width: 0 }}
        />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  if (process.env.NODE_ENV === "development") {
    console.error(error);
  }

  // Get a user-friendly message based on the error type
  const getErrorMessage = () => {
    if (error instanceof Error) {
      return error.message;
    }

    if (typeof error === "object" && error !== null && "status" in error) {
      const status = Number(error.status);

      if (status === 404) {
        return "The page you're looking for doesn't exist.";
      }

      if (status === 401 || status === 403) {
        return "You don't have permission to access this page.";
      }

      if (status >= 500) {
        return "Our server is having issues. Please try again later.";
      }
    }

    return "Something unexpected happened. Please try again.";
  };

  // Get error title
  const getErrorTitle = () => {
    if (typeof error === "object" && error !== null && "status" in error) {
      const status = Number(error.status);

      if (status === 404) {
        return "Page Not Found";
      }

      if (status === 401) {
        return "Unauthorized";
      }

      if (status === 403) {
        return "Forbidden";
      }

      if (status >= 500) {
        return "Server Error";
      }
    }

    return "Application Error";
  };

  // Get error code
  const getErrorCode = () => {
    if (typeof error === "object" && error !== null && "status" in error) {
      return error.status as string;
    }
    return "500";
  };

  return (
    <div className="min-h-screen bg-background flex flex-col items-center justify-center p-4">
      <div className="bg-componentBackground rounded-lg shadow-lg p-8 max-w-md w-full flex flex-col items-center text-center">
        {/* Logo */}
        <div className="mb-8">
          <ComponentSvg className="text-text w-40 h-auto" />
        </div>

        {/* Error icon */}
        <div className="w-24 h-24 rounded-full bg-red-100 flex items-center justify-center mb-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-14 w-14 text-red-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>

        {/* Error code */}
        <div className="text-4xl font-bold text-red-600 mb-2">
          {getErrorCode()}
        </div>

        {/* Error title */}
        <h1 className="text-2xl font-bold text-text mb-4">{getErrorTitle()}</h1>

        {/* Error message */}
        <p className="text-muted mb-8">{getErrorMessage()}</p>

        {/* Action buttons */}
        <div className="flex flex-col sm:flex-row gap-4 w-full">
          <Link
            to="/"
            className="flex-1 py-3 px-4 bg-red-600 hover:bg-red-700 text-white font-medium rounded-lg text-center transition-colors"
          >
            Return Home
          </Link>

          <button
            onClick={() => window.location.reload()}
            className="flex-1 py-3 px-4 bg-gray-200 hover:bg-gray-300 text-gray-800 font-medium rounded-lg text-center transition-colors"
          >
            Try Again
          </button>
        </div>
      </div>

      {/* Development error details (only shown in development) */}
      {process.env.NODE_ENV === "development" && error instanceof Error && (
        <div className="mt-8 max-w-2xl w-full bg-gray-800 text-gray-200 p-4 rounded-lg overflow-auto">
          <h3 className="text-lg font-semibold mb-2">
            Error Details (Development Only)
          </h3>
          <pre className="text-sm whitespace-pre-wrap break-words">
            {error.stack || error.message}
          </pre>
        </div>
      )}
    </div>
  );
};

function App() {
  return (
    <AppContextProvider>
      <SnackbarProvider>
        <Outlet />
      </SnackbarProvider>
    </AppContextProvider>
  );
}

export default withSentry(App);

export async function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  if (
    url.pathname !== "/maintenance" &&
    process.env.MAINTENANCE_MODE === "true"
  ) {
    throw redirect("/maintenance");
  } else if (
    url.pathname === "/maintenance" &&
    process.env.MAINTENANCE_MODE !== "true"
  ) {
    throw redirect("/landing-pub");
  }
  return null;
}
